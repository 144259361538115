<template>
    <section class="launch-page">

        <section-head 
                title="sectionhead.title-launch" 
                subtitle="sectionhead.subtitle-launch"
            />

        <section-options 
                :description="sectionOptionsFirst.description" 
                :icons="sectionOptionsFirst.icons"
                style="margin-top: 100px"
            />

        <section-features 
                style="margin-top: 120px" 
                :itemsCount="12" localeValues="launch.features.item-" 
                title="launch.features.title"
            />
        <section-product />

        <section-options 
                style="margin-top: 160px" 
                :description="sectionOptionsSecond.description" 
                :icons="sectionOptionsSecond.icons" 
            />
        
        <section-options  
                class="section-toptions-last"
                :description="sectionOptionsThird.description" 
                :icons="sectionOptionsThird.icons" 
            />
        <div class="blue-line-container">
            <div class="blue-line-wrapper">
                <div class="blue-line" />
            </div>
        </div>

        <section-interactions class="interations" />

        <section-footer style="background: #F4F7FF" lightsOn="on" paragraph="sectionfooter.paragraph-default" />
    </section>
</template>

<script>
import sectionHead from '../asections/sectionHead.vue';
import sectionFooter from '../asections/sectionFooter.vue';
import optionsSection from '../asections/optionsSection.vue';
import features from '../asections/features.vue';
import product from './sections/product.vue';
import interactions from './sections/interactions.vue';
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
        return {
            title: this.$t('meta_title-exchangers'),
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_exchangers-description'),
            link: getAlternatesForCurrentLanguage().concat(getCanonicals())
        }
    },
    data: () => ({
        sectionOptionsFirst: {
            description: {
                headtext: 'launch.desc.headtext',
                maintext: 'launch.desc.maintext',
                bottomtext: 'launch.desc.bottomtext',
                footertext: ''
            },

            icons: [{
                title: 'icontitle.website-centralized',
                name: 'options-website-centralized'
            },
            {
                title: 'icontitle.website-decentralized',
                name: 'options-website-decentralized'
            },
            {
                title: 'icontitle.telegram-exchange',
                name: 'options-telegram-exchanger'
            }],
        },

        sectionOptionsSecond: {
            description: {
                maintext: 'launch.desc-2.maintext',
                bottomtext: 'launch.desc-2.bottomtext',
                headtextEmphasis: 'launch.desc-2.title',
                footertext: '',
            },

            icons: [{
                title: 'icontitle.postgress',
                name: 'options-postgress'
            },
            {
                title: 'icontitle.mongo',
                name: 'options-mongo'
            },
            {
                title: 'icontitle.mysql',
                name: 'options-mysql'
            }],
        },

        sectionOptionsThird: {
            description: {
                maintext: 'launch.desc-3.maintext',
                bottomtext: 'launch.desc-3.bottomtext'
            },

            icons: [{
                title: 'icontitle.junit',
                name: 'options-junit'
            },
            {
                title: 'icontitle.spring-mock',
                name: 'options-springmock'
            },
            {
                title: 'icontitle.spring-framework',
                name: 'options-spring-framework'
            }]
        }
        
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-options': optionsSection,
        'section-features': features,
        'section-product': product,
        'section-interactions': interactions
    }
}
</script>

<style lang="sass" scoped>
@import '@/pages/launch/launchPage.sass'

.launch-page 
    color: $color--dark

.blue-line
    border-bottom: 2px solid #4071F4
    width: 40px 
    height: 2px
    position: absolute
    left: -20px
    z-index: 23
    &-container
        display: flex 
        align-items: center 
        justify-content: center
    &-wrapper 
        position: relative

.interations
    margin-top: 77px
    @media screen and (max-width: 998px)
        margin-top: 48px

.footer-radius
    position: relative
.interaction-section_background-overlay-2
    display: flex
    justify-content: center 
    background: #F4F7FF
    height: 100px
    width: 100%
    border-radius: 50% 50% 0% 0%
    z-index: -1
    margin-top: 140px

.blue-line-container
    display: flex 
    justify-content: center 
    align-items: center

.section-toptions-last
    padding-bottom: 150px 
    margin-top: 0px
    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
        padding-bottom: 0px
</style>