<template>
    <section class="product-section">
        <div class="product-section--presenter">
            <h3 class="product-section--presenter_title" v-html="$t('launch.product.title')" />
            <p class="product-section--presenter_subtitle" v-html="$t('launch.product.subtitle')" />
        </div>
        <div class="product-section--content">
            <div class="product-section--content_img-container">
                <img class="product-section--content_img" :src="require(`@/assets/img/scenes/launch-product.svg`)" alt="">
            </div>
            
        </div>
        <div class="product-section--content_container">
                <div v-for="(element, index) in list"
                    :key="index"
                    class="product-section--content_item"
                >
                    <div class="product-section--content_item_icon">
                        <img class="product-section--content_item_icon-img" :src="require(`@/assets/img/technology/${element.icon}.svg`)">
                    </div>
                    <dl>
                        <dt class="product-section--content_item_tech">{{$t(element.name)}}</dt>
                        <dd class="product-section--content_item_def">{{$t(element.description)}}</dd>
                    </dl>
                </div>
            </div>
    </section>
</template>

<script>
export default {
    data: () => ({
        list: [{
            icon: 'maven',
            name: 'launch.product.name-0',
            description: 'launch.product.description-0'
        },
        {
            icon: 'bouncycastle',
            name: 'launch.product.name-1',
            description:  'launch.product.description-1'
        },
        {
            icon: 'json',
            name: 'launch.product.name-2',
            description:  'launch.product.description-2'
        },
        {
            icon: 'progresbar',
            name: 'launch.product.name-3',
            description:  'launch.product.description-3'
        }]
    })
}
</script>

<style lang="sass" scoped>
@import '@/pages/launch/sections/product.sass'
</style>