<template>
        <section class="interaction-section">
                <svg-icon name="borderTop"></svg-icon>
                <div class="interaction-section_head">
                    <img :src="require(`@/assets/img/options/options-tiny-cube.svg`)" alt="">
                    <h3 class="interaction-section_head--title">{{$t('launch.options-3.tite')}}</h3>
                </div>
                <div class="interaction-section_icons">
                    <div v-for="(el, i) in items"
                        :key="i"
                        class="interaction-section-icons_wrapper"
                    >
                        <div class="interaction-section-icons_item">
                            <img :src="require(`@/assets/img/options/${el.name}.svg`)" alt="">
                        </div>
                        <p v-html="$t(el.title)" class="interaction-section-icons_text" />
                    </div>
                </div>
        </section>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                title: 'launch.options-3.title-1',
                name: 'options-java-bitcoin'
            },
            {
                title: 'launch.options-3.title-2',
                name: 'options-web3j'
            }
        ]
    })
}
</script>

<style lang="sass" scoped>
@import '@/pages/launch/sections/interactions.sass'
</style>